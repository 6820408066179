import React from "react";
import Layout from "../../layout";
import Header from "../../header";

import { Link } from "gatsby";

import data from "../../projects.data";
import "../../../scss/categorypage.scss";
import SEO from "../../seo";

import _ from "lodash";
export default function InstitutionPage() {
  function Hex({ imgSrc, alt = "Project Hex", projectname = "", link = "" }) {
    return (
      <li class="hex">
        <div class="hexIn">
          <a class="hexLink" href={link}>
            <img src={imgSrc} alt={alt} />
            <div className="overlay">
              <h3 className="overlay__text">{projectname}</h3>
            </div>
          </a>
        </div>
      </li>
    );
  }

  const sortedData = _.sortBy(data.institutions, [
    function (o) {
      return o.projectname;
    },
  ]);

  const allhexgons = sortedData.map((hex, index) => (
    <Hex
      imgSrc={hex.imgSrc}
      alt={"Project Hex"}
      projectname={hex.projectname}
      link={hex.link}
    />
  ));

  return (
    <Layout>
      <SEO
        title="A&D Studio | Signature Institutional Projects"
        img="https://archdesignsstudio.com/assets/linkpreviews/A&D_linkpreview-04.jpg"
        description="Explore schools, colleges, universities and other institutional buildings designed for directed learning and immersive experiences with a focus on place-making."
      />
      <Header />
      <div className="categorypage">
        <img
          className="categorypage__background"
          src="https://archdesignsstudio.com/assets/projects-re/ratnadeepinstituteofmedicalsciences/5.png"
          alt="Category Background"
        />
        <div className="categorypage__overlay"></div>

        <div className="categorypage__content">
          <h1 className="categorypage__heading">
            <Link className="categorypage__back" to={"/projects"}>
              &#8592;
            </Link>
            Institutional Projects
          </h1>

          <ul id="hexGrid">{allhexgons}</ul>
        </div>
      </div>
    </Layout>
  );
}
